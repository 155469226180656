.slide {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    transition: all 3s;
  
  }
img {
    height:100%;
    width:100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   
  
}
.show > div{
    width:100%;
    height:100%;
}
.show{
  opacity:1;

    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width:100%;
    height:100%;
 transition: all 2s;

  

}
.not-show{
  width:100%;
  height:100%;
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

opacity:0;
transition: all 2s;
  
  }

.carouselcontent{
  height:100%;
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start ;
  gap:30px;
  transition: all 0.5s ease-out;

}
.carouselContent{
  height:500px;
margin-left: 80px;
display:flex;
flex-direction: column;
gap:15px;

}

.line{
  display:flex;
  justify-content: center;
  align-items: center;
  gap:20px;

animation: pulse 5s infinite ease-in-out alternate, flash 500ms ease infinite alternate;

}
.contenttitle{
  font-family: Courier New, monospace;
font-size: 80px;
transition: all 2s;
color:rgba(244, 241, 240, 0.99);
font-weight: 500;
transition: all 10s ease-out;
animation: pulse 5s infinite ease-in-out alternate, flash 500ms ease infinite alternate;

}


.contenttitle:hover{
  color:red;
  font-weight: 900;
}
.contentbody{
  font-family: Courier New, monospace;
font-size: 20px;

color:rgba(255, 255, 255);
font-weight: 500;
animation: pulse 5s infinite ease-in-out alternate, flash 500ms ease infinite alternate;

}

.backround{
  background: linear-gradient(to top, rgba(142, 13, 13, 0.75), rgba(0, 0, 0, 0.25));
}
@keyframes fade {
    from{opacity: 0.7;}
    to{opacity: 1;;}
    
}




@keyframes fadeout {
  from{opacity: 1;}
  to{opacity: 0;}
  
}

.prev{
    top:50%;
    position: absolute;
    left:5px;
    transform: translateY(-50%);
}

.prev, .next{
  font-size:50px;
  color:rgb(208, 207, 207,0.5);


}

.next{
  top:50%;
  position: absolute;
  right:5px;
    transform: translateY(-50%);
}

.contactus, .inboxus{

  height:50px;
  width:150px;
  font-size:20px;
  color:white;
  cursor: pointer;
  animation: pulse 2s infinite ease-in-out alternate, flash 500ms ease infinite alternate;


  }
  .contentbuttons{
   
      position: absolute;
      display:flex;
      justify-content: center;
      align-items: center;
      height:100%;
     
     
    
    }
    .inboxus, .contactus{
      font-family: "Times New Roman", Times, serif;
      font-size:80%;
      background-color: #2CA1AB;
     color:white;
      height:40px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    
      position: relative;
    }
  

  
    @keyframes flash {
        from { opacity: 1; }	
        to { opacity: 0.9; }
    }
  
@keyframes pulse {
  from { transform: scale(0.98); }
  to { transform: scale(1.0); }
}
  .messageus{
  background: rgb(17, 195, 103);
  cursor: pointer;
  }
  .contentbuttons{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    gap:10px;
    cursor: pointer;
    }
    
   
button {
    border: 0;
    background-color: Transparent;
    font-size: 20px;
    
  }
  
  .page {
 
    position: absolute;
  width:100%;
  text-align: center;
  }
  .dot {
    cursor: pointer;
    height: 5px;
    width: 20px;
    margin: 0 2px;
    background-color: #ddd;
    display: inline-flex;
  
  }
  
  
  button:hover,
  .dot:hover,
  .active {
    background-color: #717171;
    opacity: 1;
   
  }

   /* Caption text */
.text {
  font-family: Arial, Helvetica, sans-serif;
   color: #f2f2f2;
   font-weight: 400;
  

   width: 100%;
   text-align: left;
 
     animation: fadeInAnimation ease 2s;
     animation-iteration-count: 1;
     animation-fill-mode: forwards;
 }




 
 .text ul{
 
 margin-top:-25px;
 }
 @keyframes fadeInAnimation {
     0% {
         opacity: 0;
     }
     100% {
         opacity: 1;
      }
 }
 .text li{
     font-size: 24px;
 
 }
 .text h3{
 
 font-size:44px;
  color: #ADF3FD;
 }
 .text p{
    font-size: 20px;
 }
 .text span{
   font-size:84px;
  color: #ADF3FD;
   
 }

 .card1 i{
  font-size:18px;
color:#789bb7;
}
.card1 ul{

margin-left: 160px;

 
letter-spacing: 1px;
line-height: 30px;
text-align:left;


}

.ceo_img_container{
 
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
 align-items: center;

}
.ceo_img{
  width:350px;
  height:auto;


  background: linear-gradient(white, white) padding-box,
              linear-gradient(to right,rgb(153, 180, 195,0.4), rgb(94, 125, 136,0.4)) border-box;
  border-radius: 50em;
  border: 1px solid transparent;
}
.ceo_img_caption{
  font-family: Arial, Helvetica, sans-serif;
     font-size: 16px;
     color:#2c7aab;
}

@media only screen and (max-width: 780px) {    
  .ceo_img{
    width:95%;
  }
  .text{
   margin-left: 40px;
  }
  .card1 ul{
 
   margin-left:0px;
  }  
 
     .contenttitle{
      
     font-size: 40px;
    
     }
  
     .contentbody{
       font-family: Courier New, monospace;
     font-size: 20px;
     width:100%;
   
     color:rgba(255, 255, 255);
     font-weight: 500;
     animation: pulse 5s infinite ease-in-out alternate, flash 500ms ease infinite alternate;
     
     }
     .carouselcontent{
       gap:10px;
       width:100%;
     }
     .carouselContent{
     width:100%;
     display:flex;
     flex-direction: column;
     align-items: center;
     margin-left: 0;
     }
     .carouselButtons{
 
       display:flex;
       flex-direction: column;
       
       gap:10px;
         }
  }
  
 