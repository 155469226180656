
:root {
    --sidenavColor: #ffffff;
    --sidenavHover:#fbfbfb;
  }

.header{
  height:8%;
    width:100%;
background:rgb(245, 245, 246);
display:flex;
justify-content: space-between;
align-items: center;
}


.actionlogo {
    margin-left:10px;
}

.dropdownmenu{

        /* display:block; */
        position: absolute;
        background:var(--sidenavColor);
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        margin-left:auto;
      }
.header button{
    border:none;
    outline: none;
    cursor: pointer;
    padding:none;
    background:white;
    color:rgb(201, 134, 126);
}
.header button:hover{
  opacity: 0.5;
}


.user{
    display:flex;
width:200px;
    height:100%;

  
}
.logout{

    display:flex;
flex-direction: column;
    height:100%;
    width:25%;
    align-items: center;
    justify-content: center;
    color:rgb(168, 76, 22);
}
.userdetail{
    display:flex;
    flex-direction: column;
    height:100%;
    width:75%;
    align-items: flex-end;
    justify-content: center;
    
}
.username{
    font-family: 'Times New Roman', Times, serif;
    font-size: 10px;
    font-weight:300;
 color:rgb(168, 76, 22);
}
.companyname{
    font-family: 'Times New Roman', Times, serif;
    font-size: 14px;
    font-weight:600;
    color:rgb(168, 76, 22);
}
.minisidebar{
    display:none;
}
.sidenav{
    height:100%;
    min-width:200px;
background:var(--sidenavColor);
color:rgb(59, 59, 59);
z-index: 4;

overflow:hidden;
box-shadow: 3px 3px 3px 3px #f3f0f0;
border:2px solid rgb(194, 160, 193);


}

.maincontent{
    height:92%;
    width:100%;
overflow: hidden;
}
.sidenavlogo{
   width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(57, 57, 57);
    border-bottom: 35px solid #fafafa;
}


.sidenavlinkscontainer{
    height:80%;
    width:100%;
   
    display:flex;
    flex-direction: column;
    align-items: center;   
}

.loginImageCaption{
    display:flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginImageCaption_1{
    font-size:60px;
    font-weight: 600;
    /* color:rgb(216, 113, 45); */
    color:rgb(115, 155, 148);
}
.loginImageCaption_2{
font-size: 16px;
font-weight: 500;
color:rgb(115, 155, 148);
}

.loginLogo{

    width:230px;
}

.sidenavImageCaption{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sidenavlinktext{
    margin-left:10px;
}

.sidenavlinkscontainer p{
    width:100%;
}
.sidenavlinks{
    width:100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    height:45px;
  padding-left: 50px;
 color:rgb(54, 53, 53);
   border-bottom:1px solid rgb(185, 182, 182,0.1);
  
}
.sidenavlinksactive{
    width:100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    height:45px;
    font-weight: 400;
  
 color:rgb(73, 73, 73);
   border-bottom:1px solid rgb(185, 182, 182,0.1);
  
}
.sidenavlinks {
    text-decoration: none;
    color:rgb(81, 81, 82);
}
.sidenavlinks:hover, .isactive{
    /* background:var(--sidenavHover); */
   font-weight: 500;
   color:rgb(115, 155, 148);
}


.sidenavImageCaption{
    display:flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sidenavImageCaption_1{
    font-size:20px;
    font-weight: 700;
    /* color:rgb(216, 113, 45); */
    color:rgb(115, 155, 148);
}
.sidenavImageCaption_2{
font-size: 12px;
font-weight: 700;
color:rgb(168, 76, 22);
}
.sidenavImageCaption_3{
    font-size: 16px;
    font-weight: 500;
    /* color:rgb(216, 113, 45); */
    color:rgb(115, 155, 148);
}
.sidenavLogo{
  margin-top: 15%;
   
  width:50px;
}

.header button{
    font-size:40px;
    color:rgb(115, 155, 148);
   background:transparent;
    }
@media screen and (max-width: 780px) {
   .sidenav{
    display:none;
   }
    .minisidebar{
        display:block;
    }
    .sidenavlinks{
   
      padding-right:50px;
  
      
    }
    }