.loginpage{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: row;
    row-gap: wrap;
}
.maincontent{
    height:92%;
    width:100%;
background:rgb(246, 246, 245);
display: flex;
flex-direction: column;
align-items: center;
gap:20px;
overflow: scroll;
}
.boldness{
    font-weight:600;
    font-size:18px;
  color:rgb(179, 25, 193);
  }

.loginlefthalf, .loginrighthalf{
    width:50%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
 
}
.loginrighthalf
{
    background: rgb(248, 248, 248);
}
.loginImageBox{
    width:500px;
    height:210px;
      overflow: hidden;
    border-radius: 10px;
  

}

.loginImage{
    transition: all 0.5s ease-out;
    animation:  fadeIn 5s, flash 500ms ease infinite alternate;
}


  @keyframes flash {
      from { opacity: 1; }	
      to { opacity: 0.8; }
  }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  } 
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
.loginImageCaption{
    display:flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loginImageCaption_1{
    font-size:60px;
    font-weight: 600;
    /* color:rgb(216, 113, 45); */
    color:rgb(115, 155, 148);
}
.loginImageCaption_2{
font-size: 24px;
font-weight: 500;
}
.loginImageCaption_3{
    font-size: 16px;
    font-weight: 500;
    /* color:rgb(216, 113, 45); */
    color:rgb(115, 155, 148);
}
.loginLogo{

    width:350px;
}
.loginlefthalf{
    display:flex;
    flex-direction: column;
    gap:15px;
    justify-content: center;
}
.loginlefthalf img{
 
  height:100%;
    width:100%;
    object-fit:cover;
    opacity: 0.7;
            
}

.loginBox{
background:rgb(255, 255, 255);
    box-shadow: 0px 0px 25px 4px #e3e3e3;
    padding:10px;
padding-top:30px;
padding-bottom: 30px;
}

.actionbox{
    display: flex;
width:100%;
justify-content:space-between;
  align-items:center;
  gap:10px;
}
.filter{
    display: flex;
    justify-content:space-between;
    align-items: center;
    width:100%;
background:rgb(238, 237, 237);
    padding:10px;
    gap:5px;
}
.filter button{
    width:100px;
    color:white;
    cursor: pointer;
    height: 30px;
    box-shadow: 1px 1px 2px 2px #cfcdd0;
}
.filter button:hover{
    opacity:0.8;
}
.filter .export_excel{
    background:green;
}
.filter .export_pdf{
    background: rgb(107, 128, 107);;
}
.filter .search{
    background:rgb(112, 112, 142);
    
}
.filter input{
    height:25px; outline: none;
    box-shadow: 1px 1px 2px 2px #eae9e9;
    border:1px solid grey;;
}

.filter input[type='date']{
 
    padding:2px;
    color:grey;
}
.actionbox input{
    width:250px;
    height:30px;
    color:#d06603;
    outline: none;
    border:1px solid #d06603;
  

}
.export{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width:80%;
    gap:10px;
   height:auto;
   padding:20px;
}
.export input{
    width:200px;
    height:30px;
    color:rgb(130, 130, 130);
    outline: none;
    border:1px solid rgb(215, 217, 219);
}

.cms_container{
    width:100%;
  
  
    display:flex;
}
.cms_left{
    width:30%;

}
.cms_middle{
    width:44%;
  border-right:4px solid rgba(148, 158, 167, 0.2);
  border-left:4px solid rgba(197, 187, 197, 0.2);
  
}
.cms_right{
    width:25%;
height:600px;
overflow: scroll;
display:flex;
flex-direction: column;
gap:4px;

}
.cms_fact{
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;

  gap:10px;
}


.cms_fact textarea {
    width: 95%;
    height: 200px;
    outline:none;
    padding: 8px 15px;
    box-sizing: border-box;
    border: 1px solid rgb(233, 230, 230);
    border-radius: 4px;
    background-color: #f2f2f2;
    font-size: 16px;
    resize: none;
    color:rgb(120, 148, 148);
    font-family: "Times New Roman", Times, serif;

  }

.cms_images{
    height:200px;
    width:200px;
    border:1px solid rgb(228, 229, 230);
}
.cms_info{
    width:200px;
}
.cms_info, .cms_video_info{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color:rgb(112, 130, 130);
    background:rgb(242, 242, 243);
}
.cms_video_player{
    height:300px;
    width:90%;
    border:1px solid rgb(228, 229, 230);
}
.cms_video_info{
    width:90%;
}
.cms_save_video_link{
    width:90%;
}
.cms_video_stream{
   cursor: pointer;
}
.cms_video_stream:hover{
    opacity:0.5;
}
.cms_video{
 width:90%;
 padding:5px;
 border-bottom: 1px solid rgb(219, 219, 219);
 display:flex;
 cursor:pointer;
}

.cms_video_thumbnail {
    width:90px;
    height:90px;
  
    border:1px solid rgb(225, 222, 222);
}
.cms_thumbnail{
    width:100%;
    height:100%;
    opacity: 0.9;
}

.cms_video_title{
    Width:70%;
    height:90px;
    background:rgb(241, 241, 243);
   display:flex;
   flex-direction: column;
   justify-content: center;
   align-items:flex-start;
   padding-left:5px;
   font-family: Arial, Helvetica, sans-serif;
   font-size: 16px;
   color:rgb(112, 130, 130);
}
 .cms_video:hover {
   border-right:1px solid rgb(130, 129, 129);
}
.cms_left, .cms_middle, .cms_right{
    display:flex;
    flex-direction:column;
   align-items: center;
}

.tables{
    width:60%;
    height:290px;
    overflow: hidden;
}
.piechart{
 background: white;
 width:400px;

}

.contestant_details{

 
font-size:16px;
color:rgb(38, 20, 36);
cursor:pointer

}
.contestant_details:hover{
    background:rgb(219, 218, 218);
}
.text_wrap{ white-space: normal; background-color:green;}
@media screen and (max-width: 780px) {
    .loginrighthalf
    {
     
      width:100%;
       
    }
    .loginlefthalf{
        display:none;
    }
    .cms_container{
        flex-direction: column;
        gap:25px;
    }
    .cms_left, .cms_middle, .cms_right{
     width:100%;
    }
     }