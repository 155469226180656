.rowcard{
    height:auto;
    display: flex;
    flex-direction: column;
    justify-content:center;
    gap:10px;
    align-items: center;
  width:24%;
  /* background-image: linear-gradient(to right, #bd8a5b , #d06603); */
  background:white;
  color:rgb(128, 127, 127);
    height:100px;
   
}

.row{
  
    width: 90%;
 border:1px solid rgba(235, 232, 232, 0.2);
 display:flex;
 justify-content: space-between;
 flex-wrap: wrap;

 }
 .titlerow{
  height:50px;
    width: 90%;
 border-bottom:1px solid rgb(206, 62, 203);;
font-weight: 700;
color:rgb(179, 25, 193);
display: flex;
align-items: flex-end;
padding-bottom:10px;
 }

 @media screen and (max-width: 780px) {
.row{
  display: flex;

  height:auto;

gap:10px;
}
.rowcard{
  width:100%;
}

}